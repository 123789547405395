<template>
<div class="organ-container">
  <div class="organ-header">
    <div class="header-tab-item">
      开始日期：
      <DatePicker type="date" v-model="filter.start" placeholder="请选择开始日期" style="width: 115px;margin-right:10px"></DatePicker>
      结束日期：
      <DatePicker type="date" v-model="filter.end" placeholder="请选择结束日期" style="width: 115px;margin-right:10px"></DatePicker>
      <Button type="primary" @click="getList" style="margin-right:10px">查询</Button>
      <Button type="success" @click="exportData">导出</Button>
    </div>
  </div>
  <div class="organ-content" ref="table">
    <u-table :data="tabData" use-virtual showBodyOverflow="title" showHeaderOverflow="title" :height="tabHeight" stripe>
      <u-table-column type="index"  label="序号" width="65"></u-table-column>
      <u-table-column prop="name" label="名称" sortable fit></u-table-column>
      <u-table-column prop="count" label="数量" sortable fit></u-table-column>
    </u-table>
  </div>
</div>
</template>
<script>
import { mapState } from 'vuex'
import exportXlsx from '@/components/xlsx/exportXlsx'
export default {
  name: 'AssetAnalyseType',
  components: {
  },
  props: {
  },
  data(){
    return {
      editModal: false,
      tabHeight:500,
      tabData:[],
      filter: {
        start: '',
        end: '',
      }
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize']),
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
  },
  mounted: function(){
    let now = new Date();
    this.filter.end = now.format('yyyy-MM-dd');
    now.setMonth(now.getMonth() - 1);
    this.filter.start = now.format('yyyy-MM-dd');
    setTimeout(this.setTableHeight, 200);
    this.getList();
  },
  methods: {
    exportData(){
      let tab = [];
      this.tabData.map(p => {
        tab.push({id: p.id, name: p.name, box: p.box, branch: p.branch, pole: p.pole, light: p.light, line: p.line});
      })
      exportXlsx(tab, {
        name: '名称',
        count: '数量',
      }, "资产导出记录");
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table.clientHeight;
    },
    getList: function(){
      let start = new Date(this.filter.start).format('yyyy-MM-dd');
      let end = new Date(this.filter.end).format('yyyy-MM-dd');
      this.$axios.post(`//${this.domains.trans}/station/asset/TimeType`, {start, end}).then(res => {
        // this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'tabData', res.data);
        }
      });
    },
  }
}
</script>
<style scoped>
.organ-container{
  /* border: solid 1px red; */
  height: 100%;
  display: flex;
  flex-direction: column;
}
.organ-header{
  height: 40px;
  margin: 3px;
  flex: none;
}
.organ-content{
  /* border: solid 1px red; */
  margin: 3px;
  height: 500px;
  flex: auto;
}
.header-tab-item{
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 40px;
  /* justify-content: flex-end; */
  /* padding: 0 20px; */
}
</style>
