<template>
<Modal v-model="showModal" :mask-closable="false" width="600px">
  <div slot="header">编辑资产归属单位</div>
  <Form ref="form" :model="form" :rules="rules" :label-width="120">
    <FormItem prop="name" label="名称">
      <Input type="text" maxlength="50" v-model="form.name" placeholder="请输入单位名称"></Input>
    </FormItem>
    <FormItem prop="linkman" label="联系人">
      <Input type="text" maxlength="50" v-model="form.linkman" placeholder="请输入联系人"></Input>
    </FormItem>
    <FormItem prop="mobile" label="联系方式">
      <Input type="text" maxlength="50" v-model="form.mobile" placeholder="请输入联系方式"></Input>
    </FormItem>
  </Form>
  <div slot="footer">
    <Button style="margin-right: 8px" @click="cancel">取消</Button>
    <Button type="primary" :loading="loading" @click="ok">保存</Button>
  </div>
</Modal>
</template>
<script>
export default {
  name: 'ModalOrganEdit',
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default () { return {}; }
    },
  },
  data () {
    return {
      loading: false,
      showModal: this.value,
      isAdd: false,
      form: {
        id: '',
        name: '',
        linkman: '',
        mobile: '',
      },
      rules: {
        name: { required: true, type: 'string', max: 50, message: '单位名称不能为空且长度不能超过50个字符', trigger: 'blur' },
      }
    }
  },
  computed: {
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.isAdd = this.item.isAdd;
        if(this.isAdd){
          this.form.id = "";
          this.form.name = '';
          this.form.linkman = "";
          this.form.mobile = "";
        }else{
          this.form.id = this.item.data.id;
          this.form.name = this.item.data.name;
          this.form.linkman = this.item.data.linkman;
          this.form.mobile = this.item.data.mobile;
        }
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  mounted: function(){
    
  },
  methods: {
    ok: async function () {
      let result = await this.$refs.form.validate();
      if(!result){
        return;
      }
      this.loading = true;
      // let req;
      // if(this.isAdd){
      //   req = this.$axios.post(`//${this.domains.trans}/asset/organ`, this.form);
      // }else{
      //   req = this.$axios.put(`//${this.domains.trans}/asset/organ/${this.form.id}`, this.form);
      // }
      this.$axios.post(`//${this.domains.trans}/station/asset/SaveOrgan`, this.form).then(res => {
        this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info("保存成功");
          this.$emit('saved', {});
          setTimeout(() => {
            this.showModal = false;
          }, 800);
        }
      });
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>