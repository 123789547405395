<template>
<div class="organ-container">
  <div class="organ-header">
    <div class="header-tab-item">
      开始日期：
      <DatePicker type="date" v-model="filter.start" placeholder="请选择开始日期" style="width: 115px;margin-right:10px"></DatePicker>
      结束日期：
      <DatePicker type="date" v-model="filter.end" placeholder="请选择结束日期" style="width: 115px;margin-right:10px"></DatePicker>
      <!-- 关联道路：
      <Select v-model="filter.road" style="width: 150px;margin-right:10px">
        <Option :value="0">全部</Option>
        <Option v-for="(item, idx) in roads" :value="item.id" :key="idx">{{item.name}} - {{item.start}} - {{item.end}}</Option>
      </Select> -->
      关联单位：
      <Select v-model="filter.organ" style="width: 150px;margin-right:10px">
        <Option :value="0">全部</Option>
        <Option v-for="(item, idx) in organs" :value="item.id" :key="idx">{{item.name}} - {{item.linkman}} - {{item.mobile}}</Option>
      </Select>
      资产类型：
      <Select v-model="filter.type" style="width: 150px;margin-right:10px">
        <Option :value="0">全部</Option>
        <Option v-for="(item, idx) in assetTypes" :value="idx" :key="idx">{{item}}</Option>
      </Select>
      <Button type="primary" @click="getList" style="margin-right:10px">查询</Button>
      <Button type="success" @click="exportData">导出</Button>
    </div>
  </div>
  <div class="organ-content" ref="table">
    <u-table :data="tabData" use-virtual showBodyOverflow="title" showHeaderOverflow="title" :height="tabHeight" stripe :pagination-show="true" :total="filter.total" :page-size="filter.size" :current-page="filter.index" @handlePageSize="handlePageSize">
      <u-table-column type="index"  label="序号" width="65"></u-table-column>
      <u-table-column prop="createTime" label="发生日期" sortable fit>
        <template slot-scope="scope">
          {{new Date(scope.row.createTime).format('yyyy-MM-dd HH:mm:ss')}}
        </template>
      </u-table-column>
      <u-table-column prop="type" label="资产类型" sortable fit>
        <template slot-scope="scope">
          {{getAssetType(scope.row.paramType)}}
        </template>
      </u-table-column>
      <u-table-column prop="assetId" label="资产名称" sortable fit>
        <template slot-scope="scope">
          {{scope.row.paramName}}
        </template>
      </u-table-column>
      <u-table-column prop="organId" label="关联单位" sortable fit>
        <template slot-scope="scope">
          {{getOrganName(scope.row.organId)}}
        </template>
      </u-table-column>
      <u-table-column prop="stationName" label="所属站点" sortable fit></u-table-column>
      <u-table-column prop="userName" label="操作人员" sortable fit></u-table-column>
      <u-table-column prop="typeName" label="操作类型" sortable fit></u-table-column>
      <u-table-column prop="content" label="操作内容" sortable fit></u-table-column>
    </u-table>
  </div>
  <!-- <div class="organ-footer">

  </div> -->
  <ModalRecordEdit v-model="editModal" :item="editItem" :organs="organs" :roads="roads" :users="users" @saved="dataSaved"/>
</div>
</template>
<script>
import { mapState } from 'vuex'
import exportXlsx from '@/components/xlsx/exportXlsx'
import ModalRecordEdit from './ModalRecordEdit'
export default {
  name: 'AssetPowerBranch',
  components: {
    ModalRecordEdit,
  },
  props: {
  },
  data(){
    return {
      editModal: false,
      tabHeight:500,
      tabData:[],
      editItem: {},
      organs: [],
      roads: [],
      users: [],
      filter: {
        index: 0,
        road: 0,
        organ: 0,
        type: 0,
        size: 15,
        start: '',
        end: '',
        total: 0,
      },
      assets: [],
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('common', ['assetTypes']),
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
  },
  mounted: function(){
    // this.getAssets();
    this.getOrgans();
    // this.getRoads();
    // this.getUsers();
    setTimeout(this.setTableHeight, 200);
    let now = new Date();
    this.filter.end = now.format('yyyy-MM-dd');
    now.setMonth(now.getMonth() - 1);
    this.filter.start = now.format('yyyy-MM-dd');
    this.getList();
  },
  methods: {
    getAssetType: function(type){
      if(type == 1)return '配电柜';
      if(type == 5)return '支路';
      if(type == 10)return '线缆';
      if(type == 14)return '灯杆';
      if(type == 199)return '单灯';
      return '-'
    },
    handlePageSize: function({page, size}){
      this.filter.index = page;
      this.filter.size = size;
      this.getList();
    },
    getAssetName: function(assetType, assetId){
      let ass = this.assets.filter(p => p.type == assetType && p.id == assetId);
      if(ass.length == 0)return "";
      return ass[0].name;
    },
    getRoadName: function(roadId){
      let rs = this.roads.filter(p => p.id == roadId);
      if(rs.length == 0)return '';
      return rs[0].name
    },
    getOrganName: function(roadId){
      let rs = this.organs.filter(p => p.id == roadId);
      if(rs.length == 0)return '';
      return rs[0].name
    },
    getPowerBoxName: function(roadId){
      let rs = this.boxes.filter(p => p.id == roadId);
      if(rs.length == 0)return '';
      return rs[0].name
    },
    exportData(){
      let tab = [];
      this.tabData.map(p => {
        tab.push({
          id: p.id,
          useDate: new Date(p.useTime).format('yyyy-MM-dd'),
          type: this.assetTypes[p.type],
          name: this.getAssetName(p.type, p.assetId),
          organName: this.getOrganName(p.organId),
          roadName: this.getRoadName(p.roadId),
          count: p.count,
          userName: p.userName,
          reason: p.reason,
          remark: p.remark
        })
      });
      exportXlsx(tab, {
        useDate: '使用日期',
        type: '资产类型',
        name: '资产名称',
        organName: '关联单位',
        roadName: '关联道路',
        count: '使用数量',
        userName: '使用人员',
        reason: '使用原因',
        remark: '备注',
      }, "资产使用记录");
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table.clientHeight;
    },
    getAssets: function(){
      this.$axios.get(`//${this.domains.trans}/asset/all`).then(res => {
        // this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
          this.$set(this, 'assets', []);
        }else{
          this.$set(this, 'assets', res.data);
        }
      });
    },
    getOrgans: function(){
      this.$axios.post(`//${this.domains.trans}/station/asset/QueryOrgan`, {}).then(res => {
        // this.loading = false;
        if(res.code != 0){
          this.$set(this, 'organs', []);
        }else{
          this.$set(this, 'organs', res.data);
        }
      });
    },
    getRoads: function(){
      this.$axios.get(`//${this.domains.trans}/asset/road`).then(res => {
        // this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
          this.$set(this, 'roads', []);
        }else{
          this.$set(this, 'roads', res.data);
        }
      });
    },
    getUsers: function(){
      this.$axios.get(`//${this.domains.trans}/asset/user`).then(res => {
        // this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
          this.$set(this, 'users', []);
        }else{
          this.$set(this, 'users', res.data);
        }
      });
    },
    getList: function(){
      let start = new Date(this.filter.start).format('yyyy-MM-dd');
      let end = new Date(this.filter.end).format('yyyy-MM-dd');
      // this.$axios.get(`//${this.domains.trans}/asset/record?start=${start}&end=${end}&road=${this.filter.road}&organ=${this.filter.organ}&type=${this.filter.type}&index=${this.filter.index}&size=${this.filter.size}`).then(res => {
      this.$axios.post(`//${this.domains.trans}/station/asset/OpLog`, {
        start: start,
        end: end,
        index: this.filter.index,
        size: this.filter.size,
        organ: this.filter.organ,
        type: this.filter.type,
      }).then(res => {
        // this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'tabData', res.data.list);
          this.$set(this.filter, 'total', res.data.count);
        }
      });
    },
    handleAdd: function(){
      this.editItem = {isAdd: true, data: {}};
      this.editModal = true;
    },
    handleEdit: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.editModal = true;
    },
    dataSaved: function(){
      this.getList();
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除资产使用记录吗?`,
        onOk: async () => {
          this.$axios.delete(`//${this.domains.trans}/asset/record/${params.row.id}`).then(res => {
            // this.loading = false;
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.$Message.info("删除成功");
              this.getList();
            }
          });
        }
      });
    },
  }
}
</script>
<style scoped>
.organ-container{
  /* border: solid 1px red; */
  height: 100%;
  display: flex;
  flex-direction: column;
}
.organ-header{
  height: 40px;
  margin: 3px;
  flex: none;
}
.organ-content{
  /* border: solid 1px red; */
  margin: 3px;
  height: 500px;
  flex: auto;
}
.organ-footer{
  height: 30px;
  flex: none;
}
.header-tab-item{
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 40px;
}
</style>
