<template>
<Modal v-model="showModal" :mask-closable="false" width="600px">
  <div slot="header">编辑配电柜资产</div>
  <Form ref="form" :model="form" :rules="rules" :label-width="120">
    <FormItem prop="type" label="使用资产类型">
      <Select v-model="form.type" @on-change="typeChanged">
        <Option v-for="(item, idx) in assetTypes" :value="parseInt(idx)" :key="idx">{{item}}</Option>
      </Select>
    </FormItem>
    <FormItem prop="assetId" label="使用资产明细">
      <Select v-model="form.assetId" @on-change="assetChanged">
        <Option v-for="(item, idx) in assets" :value="item.id" :key="idx">{{item.name}} - {{item.desc}}</Option>
      </Select>
    </FormItem>
    <FormItem prop="count" label="使用数量">
      <Input type="number" v-model="form.count" placeholder="请输入使用数量"></Input>
    </FormItem>
    <FormItem prop="useTime" label="使用日期">
      <DatePicker type="date" v-model="form.useTime" placeholder="请选择使用日期"></DatePicker>
    </FormItem>
    <FormItem prop="userId" label="使用人员">
      <Select v-model="form.userId" @on-change="userChanged">
        <Option v-for="(item, idx) in users" :value="item.id" :key="idx">{{item.name}}</Option>
      </Select>
    </FormItem>
    <FormItem prop="reason" label="使用原因">
      <Input type="textarea" maxlength="200" :rows="5" v-model="form.reason" placeholder="请输入使用原因"></Input>
    </FormItem>
    <FormItem prop="remark" label="备注">
      <Input type="textarea" maxlength="200" :rows="5" v-model="form.remark" placeholder="备注"></Input>
    </FormItem>
  </Form>
  <div slot="footer">
    <Button style="margin-right: 8px" @click="cancel">取消</Button>
    <Button type="primary" :loading="loading" @click="ok">保存</Button>
  </div>
</Modal>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'ModalRecordEdit',
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default () { return {}; }
    },
    roads: {
      type: Array,
      default () { return []; }
    },
    organs: {
      type: Array,
      default () { return []; }
    },
    users: {
      type: Array,
      default () { return []; }
    },
  },
  data () {
    return {
      loading: false,
      showModal: this.value,
      isAdd: false,
      assets: [],
      form: {
        id: '',
        type: '',
        assetId: 0,
        roadId: 0,
        organId: 0,
        count: 0,
        useTime: '',
        userId: 0,
        userName: '',
        reason: '',
        remark: '',
      },
      rules: {
        name: { required: true, type: 'string', max: 50, message: '单位名称不能为空且长度不能超过50个字符', trigger: 'blur' },
      }
    }
  },
  computed: {
    ...mapState('common', ['assetTypes']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.isAdd = this.item.isAdd;
        if(this.isAdd){
          this.form.id = "";
          this.form.type = '';
          this.form.roadId = 0;
          this.form.organId = 0;
          this.form.assetId = 0;
          this.form.count = 0;
          this.form.useTime = new Date().format('yyyy-MM-dd');
          this.form.userId = 0;
          this.form.userName = '';
          this.form.reason = '';
          this.form.remark = '';
        }else{
          this.form.id = this.item.data.id;
          this.form.type = this.item.data.type;
          this.form.roadId = this.item.data.roadId;
          this.form.organId = this.item.data.organId;
          this.form.assetId = this.item.data.assetId;
          this.form.count = this.item.data.count;
          this.form.useTime = this.item.data.useTime;
          this.form.userId = this.item.data.userId;
          this.form.userName = this.item.data.userName;
          this.form.reason = this.item.data.reason;
          this.form.remark = this.item.data.remark;
          this.typeChanged();
          this.assetChanged();
          this.userChanged();
        }
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  mounted: function(){
    
  },
  methods: {
    userChanged: function(){
      if(this.form.userId == 0){
        this.form.userName = '';
      }else{
        let us = this.users.filter(p => p.id == this.form.userId);
        if(us.length == 0){
          this.form.userName = '';
        }else{
          this.form.userName = us[0].name;
        }
      }
    },
    assetChanged: function(){
      if(this.form.assetId == 0){
        this.form.organId = 0;
        this.form.roadId = 0;
      }else{
        let ass = this.assets.filter(p => p.id == this.form.assetId);
        if(ass.length == 0){
          this.form.organId = 0;
          this.form.roadId = 0;
        }else{
          this.form.organId = ass[0].organId;
          this.form.roadId = ass[0].roadId;
        }
      }
    },
    typeChanged: function(){
      switch(this.form.type){
        case 1: this.getBoxes(); break;
        case 2: this.getBranchs(); break;
        case 3: this.getPoles(); break;
        case 4: this.getLights(); break;
        case 5: this.getLines(); break;
        default: 
          this.$set(this, 'assets', []);
          break;
      }
    },
    getBoxes: function(){
      this.$axios.get(`//${this.domains.trans}/asset/powerbox`).then(res => {
        if(res.code != 0){
          this.$set(this, 'assets', []);
        }else{
          let ass = [];
          res.data.map(p => {
            ass.push({
              id: p.id,
              name: p.name,
              roadId: p.roadId,
              organId: p.organId,
              road: this.getRoadName(p.roadId),
              organ: this.getOrganName(p.organId),
              desc: this.getRoadName(p.roadId)
            })
          });
          this.$set(this, 'assets', ass);
        }
      });
    },
    getBranchs: function(){
      this.$axios.get(`//${this.domains.trans}/asset/powerbranch`).then(res => {
        if(res.code != 0){
          this.$set(this, 'assets', []);
        }else{
          let ass = [];
          res.data.map(p => {
            ass.push({
              id: p.id,
              name: p.name,
              roadId: p.roadId,
              organId: p.organId,
              road: this.getRoadName(p.roadId),
              organ: this.getOrganName(p.organId),
              desc: this.getRoadName(p.roadId)
            })
          });
          this.$set(this, 'assets', ass);
        }
      });
    },
    getPoles: function(){
      this.$axios.get(`//${this.domains.trans}/asset/pole`).then(res => {
        if(res.code != 0){
          this.$set(this, 'assets', []);
        }else{
          let ass = [];
          res.data.map(p => {
            ass.push({
              id: p.id,
              name: p.name,
              roadId: p.roadId,
              organId: p.organId,
              road: this.getRoadName(p.roadId),
              organ: this.getOrganName(p.organId),
              desc: `高度:${p.height},类型:${p.type},灯臂类型:${p.branchType},数量:${p.count}`
            })
          });
          this.$set(this, 'assets', ass);
        }
      });
    },
    getLights: function(){
      this.$axios.get(`//${this.domains.trans}/asset/light`).then(res => {
        if(res.code != 0){
          this.$set(this, 'assets', []);
        }else{
          let ass = [];
          res.data.map(p => {
            ass.push({
              id: p.id,
              name: p.name,
              roadId: p.roadId,
              organId: p.organId,
              road: this.getRoadName(p.roadId),
              organ: this.getOrganName(p.organId),
              desc: `功率:${p.power},类型:${p.type},数量:${p.count}`
            })
          });
          this.$set(this, 'assets', ass);
        }
      });
    },
    getLines: function(){
      this.$axios.get(`//${this.domains.trans}/asset/line`).then(res => {
        if(res.code != 0){
          this.$set(this, 'assets', []);
        }else{
          let ass = [];
          res.data.map(p => {
            ass.push({
              id: p.id,
              name: p.name,
              roadId: p.roadId,
              organId: p.organId,
              road: this.getRoadName(p.roadId),
              organ: this.getOrganName(p.organId),
              desc: `芯数:${p.count},材质:${p.type},每芯截面:${p.area},长度${p.length}`
            })
          });
          this.$set(this, 'assets', ass);
        }
      });
    },
    getRoadName: function(roadId){
      let rs = this.roads.filter(p => p.id == roadId);
      if(rs.length == 0)return '';
      return rs[0].name
    },
    getOrganName: function(roadId){
      let rs = this.organs.filter(p => p.id == roadId);
      if(rs.length == 0)return '';
      return rs[0].name
    },
    ok: async function () {
      let result = await this.$refs.form.validate();
      if(!result){
        return;
      }
      this.loading = true;
      let req;
      this.form.useTime = new Date(this.form.useTime).format('yyyy-MM-dd')
      if(this.isAdd){
        req = this.$axios.post(`//${this.domains.trans}/asset/record`, this.form);
      }else{
        req = this.$axios.put(`//${this.domains.trans}/asset/record/${this.form.id}`, this.form);
      }
      req.then(res => {
        this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info("保存成功");
          this.$emit('saved', {});
          setTimeout(() => {
            this.showModal = false;
          }, 800);
        }
      });
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>