<template>
<Modal v-model="showModal" :mask-closable="false" width="600px">
  <div slot="header">编辑配电柜资产</div>
  <Form ref="form" :model="form" :rules="rules" :label-width="120">
    <!-- <FormItem prop="name" label="名称">
      <Input type="text" maxlength="50" v-model="form.name" placeholder="请输入单位名称"></Input>
    </FormItem> -->
    <FormItem prop="organId" label="归属单位">
      <Select v-model="form.organId">
        <Option v-for="(item, idx) in organs" :value="item.id" :key="idx">{{item.name}} - {{item.linkman}} - {{item.mobile}}</Option>
      </Select>
    </FormItem>
    <FormItem prop="location" label="材质">
      <Input ref="location" type="text" v-model="form.meteria" placeholder="请输入材质">
      </Input>
    </FormItem>
    <FormItem prop="install" label="安装日期">
      <DatePicker ref="install" type="date" format="yyyy-MM-dd" v-model="form.buildDate" placeholder="请选择安装日期"></DatePicker>
    </FormItem>
    <FormItem prop="poleType" label="灯杆类型">
      <Input ref="poleType" type="text" v-model="form.poleType" placeholder="请输入灯杆类型">
      </Input>
    </FormItem>
    <FormItem prop="armType" label="灯臂类型">
      <Input ref="armType" type="text" v-model="form.armType" placeholder="请输入灯臂类型">
      </Input>
    </FormItem>
    <FormItem prop="height" label="高度">
      <Input ref="height" type="number" v-model="form.height" placeholder="请输入高度">
      </Input>
    </FormItem>
    <!-- <FormItem prop="roadId" label="关联道路">
      <Select v-model="form.roadId">
        <Option v-for="(item, idx) in roads" :value="item.id" :key="idx">{{item.name}} - {{item.start}} - {{item.end}}</Option>
      </Select>
    </FormItem>
    <FormItem prop="organId" label="关联单位">
      <Select v-model="form.organId">
        <Option v-for="(item, idx) in organs" :value="item.id" :key="idx">{{item.name}} - {{item.linkman}} - {{item.mobile}}</Option>
      </Select>
    </FormItem>
    <FormItem prop="organId" label="关联设备">
      <Select v-model="form.stationId">
        <Option v-for="(item, idx) in selectedDevices" :value="item.id" :key="idx">{{item.name}}</Option>
      </Select>
    </FormItem> -->
  </Form>
  <div slot="footer">
    <Button style="margin-right: 8px" @click="cancel">取消</Button>
    <Button type="primary" :loading="loading" @click="ok">保存</Button>
  </div>
</Modal>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'ModalBoxEdit',
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default () { return {}; }
    },
    roads: {
      type: Array,
      default () { return []; }
    },
    organs: {
      type: Array,
      default () { return []; }
    },
  },
  data () {
    return {
      loading: false,
      showModal: this.value,
      isAdd: false,
      form: {
        id: 0,
        buildDate: '',
        meteria: '',
        height: 0,
        poleType: '',
        armType: '',
        organId: 0,
      },
      rules: {
        name: { required: true, type: 'string', max: 50, message: '单位名称不能为空且长度不能超过50个字符', trigger: 'blur' },
      }
    }
  },
  computed: {
    ...mapState("group", ["selectedDevices", "selectedNode"]),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.isAdd = this.item.isAdd;
        // console.log('edit form', this.item)
        if(this.isAdd){
          this.form.id = 0;
          this.form.buildDate = new Date().format('yyyy-MM-dd');
          this.form.meteria = '铝合金';
          this.form.poleType = '';
          this.form.armType = '';
          this.form.height = 0;
          this.form.organId = 0;
        }else{
          this.form.id = this.item.data.id;
          this.form.buildDate = this.item.data.buildDate;
          this.form.meteria = this.item.data.meteria;
          this.form.poleType = this.item.data.poleType;
          this.form.armType = this.item.data.armType;
          this.form.height = this.item.data.height;
          this.form.organId = this.item.data.organId;
        }
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  mounted: function(){
    
  },
  methods: {
    ok: async function () {
      let result = await this.$refs.form.validate();
      if(!result){
        return;
      }
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/asset/SaveLampPole`, this.form).then(res => {
        this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info("保存成功");
          this.$emit('saved', {});
          setTimeout(() => {
            this.showModal = false;
          }, 800);
        }
      })
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>