<template>
<div class="organ-container">
  <div class="organ-header">
    <div class="header-tab-item">
      单位名称：<Input type="text" maxlength="50" v-model="filter.name" style="width: 150px;margin-right:10px"></Input>
      联系人：<Input type="text" maxlength="50" v-model="filter.linkman" style="width: 150px;margin-right:10px"></Input>
      联系方式：<Input type="text" maxlength="50" v-model="filter.mobile" style="width: 150px;margin-right:10px"></Input>
      <Button type="primary" @click="getList" style="margin-right:10px">查询</Button>
      <Button type="success" @click="exportData">导出</Button>
    </div>
  </div>
  <div class="organ-content" ref="table">
    <u-table :data="tabData" use-virtual showBodyOverflow="title" showHeaderOverflow="title" :height="tabHeight" stripe>
      <u-table-column type="index"  label="序号" width="65"></u-table-column>
      <u-table-column prop="name" label="单位名称" sortable fit></u-table-column>
      <u-table-column prop="linkman" label="联系人" sortable fit></u-table-column>
      <u-table-column prop="mobile" label="联系方式" sortable fit></u-table-column>
      <u-table-column width="120">
        <template slot="header" slot-scope="scope">
          <AuthButton opCode='aoa' style="margin-right: 5px" @click="handleAdd(scope)">新增</AuthButton>
        </template>
        <template slot-scope="scope">
          <AuthButton opCode='aoe' style="margin-right: 5px" @click="handleEdit(scope)">编辑</AuthButton>
          <AuthButton opCode='aod' type="error" style="margin-right: 5px" @click="handleDelete(scope)">删除</AuthButton>
        </template>
      </u-table-column>
    </u-table>
  </div>
  <ModalOrganEdit v-model="editModal" :item="editItem" @saved="dataSaved"/>
</div>
</template>
<script>
import { mapState } from 'vuex'
import exportXlsx from '@/components/xlsx/exportXlsx'
import ModalOrganEdit from './ModalOrganEdit'
// import AssetConfigOrgan from './organ/Index'
// import AssetPowerIndex from './power/Index'
// import exportXlsx from '@/components/xlsx/exportXlsx'
export default {
  name: 'AssetConfigOrgan',
  components: {
    ModalOrganEdit,
  },
  props: {
  },
  data(){
    return {
      editModal: false,
      tabHeight:500,
      tabData:[],
      editItem: {},
      filter: {
        name: '',
        linkman: '',
        mobile: '',
      }
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize']),
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
  },
  mounted: function(){
    setTimeout(this.setTableHeight, 200);
    this.getList();
  },
  methods: {
    exportData(){
      let tab=JSON.parse(JSON.stringify(this.tabData));
      exportXlsx(tab, {
        name: '单位名称',
        linkman: '联系人',
        mobile: '联系方式'
      }, "资产归属单位导出记录");
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table.clientHeight;
    },
    getList: function(){
      this.$axios.post(`//${this.domains.trans}/station/asset/QueryOrgan`,this.filter).then(res => {
        // this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'tabData', res.data);
        }
      });
    },
    handleAdd: function(){
      this.editItem = {isAdd: true, data: {}};
      this.editModal = true;
    },
    handleEdit: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.editModal = true;
    },
    dataSaved: function(){
      this.getList();
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除单位 ${params.row.name} 吗?`,
        onOk: async () => {
          // this.$axios.delete(`//${this.domains.trans}/asset/organ/${params.row.id}`).then(res => {
          this.$axios.post(`//${this.domains.trans}/station/asset/DeleteOrgan`,{id: params.row.id}).then(res => {
            // this.loading = false;
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.$Message.info("删除成功");
              this.getList();
            }
          });
        }
      });
    },
  }
}
</script>
<style scoped>
.organ-container{
  /* border: solid 1px red; */
  height: 100%;
  display: flex;
  flex-direction: column;
}
.organ-header{
  height: 40px;
  margin: 3px;
  flex: none;
}
.organ-content{
  /* border: solid 1px red; */
  margin: 3px;
  height: 500px;
  flex: auto;
}
.header-tab-item{
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 40px;
}
</style>
