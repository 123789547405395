<template>
  <div class="asset-container">
    <div class="asset-slider">
      <div :class="['asset-slider-item', item.code == current ? 'asset-item-selected' : '']" v-for="(item, idx) in menus" :key="idx" @click="clickMenu(item)">
        <div v-if="item.code == current" class="blockActive"></div>
        <img :src="[ item.code == current ? item.iconActive : item.icon]" alt="" :class="['asset-item-img', item.code == current ? 'asset-item-imgActive' : '']">
        <div class="asset-item-title">{{item.name}}</div>
      </div>
    </div>
    <div class="asset-content">
      <template v-for="(item, idx) in menus">
        <component v-if="item.code == current" :key="idx" v-bind:is="item.com" :args="item.args"></component>
      </template>
    </div>
  </div>
</template>
<script>
import AssetConfigIndex from './config/Index'
import AssetPowerIndex from './power/Index'
// import AssetOtherIndex from './other/Index'
import AssetRecordIndex from './record/Index'
import AssetAnalyseIndex from './analyse/Index'
export default {
  name: 'Home',
  components: {
  },
  props: {
  },
  data() {
    return {
      deploy: false,
      current: 's2',
      menus: [
        { code: 's1', name: '资产信息配置', com: AssetConfigIndex, args: {}, icon: this.img.assetManage.info, iconActive: this.img.assetManage.infoActive },
        { code: 's2', name: '资产台账', com: AssetPowerIndex, args: {}, icon: this.img.assetManage.PowerBox, iconActive: this.img.assetManage.PowerBoxActive },
        // { code: 's3', name: '其他资产管理', com: AssetOtherIndex, args: {}, icon: this.img.assetManage.otherAssets, iconActive: this.img.assetManage.otherAssetsActive },
        { code: 's4', name: '资产变更查询', com: AssetRecordIndex, args: {}, icon: this.img.assetManage.AssetChange, iconActive: this.img.assetManage.AssetChangeActive },
        { code: 's5', name: '资产信息统计', com: AssetAnalyseIndex, args: {}, icon: this.img.assetManage.AssetInfo, iconActive: this.img.assetManage.AssetInfoActive },
      ],
    }
  },
  mounted: function () {
  },
  methods: {
    clickMenu: function (item) {
      this.current = item.code;
    },
    menuSelected: function (name) {
      if (this.currentName == name) return;
      this.currentName = name;
      this.$router.push(`/index/${this.sys}/workorder/${name}?code=${this.types[this.sys]}`);
    },
  }
}
</script>
<style scoped>
.asset-container {
  /* border: solid 1px red; */
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #e3e9ef;
  padding: 3px;
}
.asset-slider {
  width: 250px;
  flex: none;
  margin-right: 5px;
  background-color: #ffffff;
}
.asset-slider-item {
  height: 48px;
  margin-bottom: 5px;
  cursor: pointer;
  line-height: 48px;
  display: flex;
}
.asset-item-selected {
  background-color: #ddeffe;
}
.asset-content {
  flex: auto;
  background-color: #ffffff;
  margin: 20px;
  padding: 30px;
}
.blockActive {
  width: 6px;
  height: 48px;
  background: linear-gradient(0deg, #3176fb, #5eb4ff);
  border-radius: 0px 4px 4px 0px;
}
.asset-item-title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
}
.asset-item-img {
  width: 20px;
  height: 22px;
  vertical-align: middle;
  margin-top: 13px;
  margin-left: 17px;
  margin-right: 17px;
}
.asset-item-imgActive {
  margin-left: 12px;
}
.asset-item-selected .asset-item-title {
  color: #3880fc;
}
</style>
