<template>
<Modal v-model="showModal" :mask-closable="false" width="600px">
  <div slot="header">编辑配电柜资产</div>
  <Form ref="form" :model="form" :rules="rules" :label-width="120">
    <!-- <FormItem prop="name" label="名称">
      <Input type="text" maxlength="50" v-model="form.name" placeholder="请输入单位名称"></Input>
    </FormItem> -->
    <!-- <FormItem prop="roadId" label="关联道路">
      <Select v-model="form.roadId">
        <Option v-for="(item, idx) in roads" :value="item.id" :key="idx">{{item.name}} - {{item.start}} - {{item.end}}</Option>
      </Select>
    </FormItem> -->
    <FormItem prop="organId" label="关联单位">
      <Select v-model="form.organId">
        <Option v-for="(item, idx) in organs" :value="item.id" :key="idx">{{item.name}} - {{item.linkman}} - {{item.mobile}}</Option>
      </Select>
    </FormItem>
    <!-- <FormItem prop="powerBoxId" label="关联配电柜">
      <Select v-model="form.powerBoxId">
        <Option v-for="(item, idx) in boxes" :value="item.id" :key="idx">{{item.name}}</Option>
      </Select>
    </FormItem> -->
  </Form>
  <div slot="footer">
    <Button style="margin-right: 8px" @click="cancel">取消</Button>
    <Button type="primary" :loading="loading" @click="ok">保存</Button>
  </div>
</Modal>
</template>
<script>
// import { mapState } from 'vuex'
export default {
  name: 'ModalBranchEdit',
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default () { return {}; }
    },
    roads: {
      type: Array,
      default () { return []; }
    },
    organs: {
      type: Array,
      default () { return []; }
    },
    boxes: {
      type: Array,
      default () { return []; }
    },
  },
  data () {
    return {
      loading: false,
      showModal: this.value,
      isAdd: false,
      form: {
        id: '',
        // name: '',
        // roadId: 0,
        organId: 0,
        // stationId: 0,
        // powerBoxId: 0,
      },
      rules: {
        // name: { required: true, type: 'string', max: 50, message: '单位名称不能为空且长度不能超过50个字符', trigger: 'blur' },
      }
    }
  },
  computed: {
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.isAdd = this.item.isAdd;
        if(this.isAdd){
          this.form.id = "";
          // this.form.name = '';
          // this.form.roadId = 0;
          this.form.organId = 0;
          // this.form.stationId = 0;
          // this.form.powerBoxId = 0;
        }else{
          this.form.id = this.item.data.id;
          // this.form.name = this.item.data.name;
          // this.form.roadId = this.item.data.roadId;
          this.form.organId = this.item.data.organId;
          // this.form.stationId = this.item.data.stationId;
          // this.form.powerBoxId = this.item.data.powerBoxId;
        }
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  mounted: function(){
    
  },
  methods: {
    ok: async function () {
      let result = await this.$refs.form.validate();
      if(!result){
        return;
      }
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/asset/SavePowerBranch`, this.form).then(res => {
        this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info("保存成功");
          this.$emit('saved', {});
          setTimeout(() => {
            this.showModal = false;
          }, 800);
        }
      });
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>