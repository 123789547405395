<template>
<div class="organ-container">
  <div class="organ-header">
    <div class="header-tab-item">
      名称：<Input type="text" maxlength="50" v-model="filter.name" style="width: 150px;margin-right:10px"></Input>
      安装位置<Input type="text" maxlength="50" v-model="filter.road" style="width: 150px;margin:10px"></Input>
      <!-- 关联道路：
      <Select v-model="filter.road" style="width: 150px;margin-right:10px">
        <Option :value="0">全部</Option>
        <Option v-for="(item, idx) in roads" :value="item.id" :key="idx">{{item.name}} - {{item.start}} - {{item.end}}</Option>
      </Select>
      关联单位：
      <Select v-model="filter.organ" style="width: 150px;margin-right:10px">
        <Option :value="0">全部</Option>
        <Option v-for="(item, idx) in organs" :value="item.id" :key="idx">{{item.name}} - {{item.linkman}} - {{item.mobile}}</Option>
      </Select> -->
      <Button type="primary" @click="getList" style="margin-right:10px">查询</Button>
      <!-- <Button type="success" @click="exportData">导出</Button> -->
    </div>
  </div>
  <div class="organ-content" ref="table">
    <u-table :data="tabData" use-virtual showBodyOverflow="title" showHeaderOverflow="title" :height="tabHeight" stripe>
      <u-table-column type="index"  label="序号" width="65"></u-table-column>
      <u-table-column prop="name" label="名称" sortable fit></u-table-column>
      <u-table-column prop="organName" label="归属单位" sortable fit></u-table-column>
      <u-table-column prop="stationName" label="所属配电柜" sortable fit>
        <template slot-scope="scope">
          {{scope.row.stationName}}
        </template>
      </u-table-column>
      <u-table-column prop="street" label="安装位置" sortable fit>
        <template slot-scope="scope">
          {{scope.row.street}}
        </template>
      </u-table-column>
      <u-table-column prop="buildDate" label="安装日期" sortable fit>
        <template slot-scope="scope">
          {{scope.row.buildDate}}
        </template>
      </u-table-column>
      <u-table-column prop="meteria" label="材质" sortable fit>
        <template slot-scope="scope">
          {{scope.row.meteria}}
        </template>
      </u-table-column>
      <u-table-column prop="height" label="高度" sortable fit>
        <template slot-scope="scope">
          {{scope.row.height}}
        </template>
      </u-table-column>
      <u-table-column prop="poleType" label="灯杆类型" sortable fit>
        <template slot-scope="scope">
          {{scope.row.poleType}}
        </template>
      </u-table-column>
      <u-table-column prop="armType" label="灯臂类型" sortable fit>
        <template slot-scope="scope">
          {{scope.row.armType}}
        </template>
      </u-table-column>
      <u-table-column width="120">
        <!-- <template slot="header" slot-scope="scope">
          <AuthButton opCode='apa' style="margin-right: 5px" @click="handleAdd(scope)">新增</AuthButton>
        </template> -->
        <template slot-scope="scope">
          <Button v-if="funCodes('___')" size="small" type="primary" @click="handleEdit(scope)">编辑</Button>
          <!-- <AuthButton opCode='ape' style="margin-right: 5px" @click="handleEdit(scope)">编辑</AuthButton>
          <AuthButton opCode='apd' type="error" style="margin-right: 5px" @click="handleDelete(scope)">删除</AuthButton> -->
        </template>
      </u-table-column>
    </u-table>
  </div>
  <ModalBoxEdit v-model="editModal" :item="editItem" :organs="organs" :roads="roads" @saved="dataSaved"/>
</div>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
import exportXlsx from '@/components/xlsx/exportXlsx'
import ModalBoxEdit from './ModalBoxEdit'
export default {
  name: 'AssetPowerBox',
  components: {
    ModalBoxEdit,
  },
  props: {
  },
  data(){
    return {
      editModal: false,
      tabHeight:500,
      tabData:[],
      editItem: {},
      organs: [],
      roads: [],
      filter: {
        name: '',
        road: '',
      }
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize']),
    ...mapState("group", ["selectedDevices", "selectedNode"]),
    ...mapState('common', ['powerBoxAlarmTypes', 'productCodes', 'powerBoxStatus', 'poleType', 'powerBoxTypes']),
    ...mapGetters('auth', ['funCodes']),
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
  },
  mounted: function(){
    this.getOrgans();
    // this.getRoads();
    setTimeout(this.setTableHeight, 200);
    this.getList();
  },
  methods: {
    getStationType: function(type){
      if(this.appType == 'pole'){
        return this.poleType[type];
      }else{
        return this.powerBoxTypes[type];
      }
    },
    getRoadName: function(roadId){
      let rs = this.roads.filter(p => p.id == roadId);
      if(rs.length == 0)return '';
      return rs[0].name
    },
    getOrganName: function(roadId){
      let rs = this.organs.filter(p => p.id == roadId);
      if(rs.length == 0)return '';
      return rs[0].name
    },
    getStationName: function(roadId){
      let rs = this.selectedDevices.filter(p => p.id == roadId);
      if(rs.length == 0)return '';
      return rs[0].name
    },
    exportData(){
      let tab=JSON.parse(JSON.stringify(this.tabData));
      exportXlsx(tab, {
        name: '名称',
        linkman: '联系人',
        mobile: '联系方式'
      }, "资产归属单位导出记录");
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table.clientHeight;
    },
    getOrgans: function(){
      this.$axios.post(`//${this.domains.trans}/station/asset/QueryOrgan`, {}).then(res => {
        // this.loading = false;
        if(res.code != 0){
          this.$set(this, 'organs', []);
        }else{
          this.$set(this, 'organs', res.data);
        }
      });
    },
    getRoads: function(){
      this.$axios.get(`//${this.domains.trans}/asset/road`).then(res => {
        // this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
          this.$set(this, 'roads', []);
        }else{
          this.$set(this, 'roads', res.data);
        }
      });
    },
    getList: function(){
      // this.$axios.get(`//${this.domains.trans}/asset/powerbox?name=${this.filter.name}&road=${this.filter.road}&organ=${this.filter.organ}`).then(res => {
      this.$axios.post(`//${this.domains.trans}/station/asset/QueryLampPole`, this.filter).then(res => {
        // this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'tabData', res.data);
        }
      });
    },
    handleAdd: function(){
      this.editItem = {isAdd: true, data: {}};
      this.editModal = true;
    },
    handleEdit: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.editModal = true;
    },
    dataSaved: function(){
      this.getList();
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除配电柜 ${params.row.name} 吗?`,
        onOk: async () => {
          this.$axios.delete(`//${this.domains.trans}/asset/powerbox/${params.row.id}`).then(res => {
            // this.loading = false;
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.$Message.info("删除成功");
              this.getList();
            }
          });
        }
      });
    },
  }
}
</script>
<style scoped>
.organ-container{
  /* border: solid 1px red; */
  height: 100%;
  display: flex;
  flex-direction: column;
}
.organ-header{
  height: 40px;
  margin: 3px;
  flex: none;
}
.organ-content{
  /* border: solid 1px red; */
  margin: 3px;
  height: 500px;
  flex: auto;
}
.header-tab-item{
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 40px;
}
</style>
